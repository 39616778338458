import React from "react";
import Layout from "../../other/layout";
import SEO from "../../other/seo";
import CustomerServiceTopics from "../../components/AC-StaticPages/tools-resources/CustomerServiceTopics";

export default function CustomerService() {
  return (
    <Layout>
      <SEO title="Customer Service Topics" />

      <CustomerServiceTopics />
    </Layout>
  );
}
