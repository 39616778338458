import React from "react";
import styles from "../style/styles.module.css";

import iconContact from "../../../assets/img/tools/icon-contact.gif";
import dfsCustomerService from "../../../assets/img/tools/DFS_Customer_Service_Icon.jpg";
import iconMyAccount from "../../../assets/img/tools/icon-my-account.gif";
import iconOrder from "../../../assets/img/tools/icon-order.gif";
import iconShipping from "../../../assets/img/tools/icon-shipping.gif";
import iconSecurity from "../../../assets/img/tools/icon-site-security.gif";
import { Link } from "@reach/router";
import { PREVIEW, VID } from "../../../project-config";
const CustomerServiceTopics = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1 className={styles.title}>Customer Service Topics</h1>
          <div class={styles.reasonsContents}>
            <div class={styles.helpHeader}>
              <p class="helpPage">
                Have a question? Check these popular topics, by clicking any
                subject or question. For additional help, please contact a
                customer service representative.
              </p>
            </div>

            <div className={styles.tableFlex} id="tableContent">
              <div className={styles.flexRow}>
                <img width="80" alt="contact us" src={iconContact} />
                <ul>
                  <div class="subTitleHelp">
                    <a href="/tools-resources/customer-service-topics/#contactUs">
                      Contacting Us
                    </a>
                  </div>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#contactWhere">
                      Where do I call for assistance?
                    </a>
                  </li>

                  <li>
                    <a href="/tools-resources/customer-service-topics/#contactCanIGet">
                      Can I get a product sample?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#contactHow">
                      How do I request a catalogue?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#contactCanI">
                      Send a comment or suggestion?
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.flexRow}>
                <img width="80" alt="my account" src={iconMyAccount} />
                <ul>
                  <div class="subTitleHelp">
                    <a href="/tools-resources/customer-service-topics/#account">
                      My Account/Signing In
                    </a>
                  </div>
                  <li>
                    <a
                      id="mypassword"
                      href="/tools-resources/customer-service-topics/#accountWhat"
                    >
                      What if I forget my password?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#accountWhatAre">
                      What are my payment options?
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.flexRow}>
                <img width="80" alt="site security" src={iconSecurity} />
                <ul>
                  <div class="subTitleHelp">
                    <a href="/tools-resources/customer-service-topics/#site">
                      Site Security
                    </a>
                  </div>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#siteAre">
                      Are my online cheque orders secure?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#siteWhat">
                      What do you do with my information?
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.flexRow}>
                <img width="80" alt="orders and reorders" src={iconOrder} />
                <ul>
                  <br />
                  <div class="subTitleHelp">
                    <a href="/tools-resources/customer-service-topics/#orders">
                      Orders &amp; Reorders
                    </a>
                  </div>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#ordersHow">
                      How do I find products for my software?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#ordersWill">
                      Will I see my total cost before ordering?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#ordersHowDo">
                      How do I check order status?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#ordersCanI">
                      Can I change or cancel an order?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#ordersHowDoI">
                      How do I reorder?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#ordersWhat">
                      What if I'm not satisfied?
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.flexRow}>
                <img
                  width="80"
                  alt="shipping and handling"
                  src={iconShipping}
                />
                <ul>
                  <br />
                  <div class="subTitleHelp">
                    <a href="/tools-resources/customer-service-topics/#shipping">
                      Shipping &amp; Handling
                    </a>
                  </div>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#shippingHow">
                      How is my order shipped?
                    </a>
                  </li>

                  <li>
                    <a href="/tools-resources/customer-service-topics/#shippingHowLong">
                      How long will my order take?
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.flexRow}>
                <img
                  width="80"
                  alt="shipping and handling"
                  src={dfsCustomerService}
                />
                <ul>
                  <br />
                  <div class="subTitleHelp">
                    <a href="/tools-resources/customer-service-topics/#pcsHelp">
                      DFS Promotion Code/Coupon Code
                    </a>
                  </div>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#pcsHelpWhat">
                      What is a Promotion Code or Coupon Code?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#pcsHelpWhere">
                      Where can I find my Promotion Code or Coupon Code?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#pcsHelpHow">
                      How do you qualify?
                    </a>
                  </li>
                  <li>
                    <a href="/tools-resources/customer-service-topics/#pcsHelpMultiple">
                      What if I have more than one Promotion Code or Coupon
                      Code?
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="mainHeader">
              <a id="contactUs" name="contactUs"></a>
              <h2>CONTACTING US</h2>
              <br />
            </div>
            <div class={styles.helpHeader}>
              <p>
                <a id="contactWhere" name="contactWhere"></a>
                Where do I call for assistance?
                <br />
                Our sales and service representatives are happy to assist you -
                simply call 800.490.6327 Monday - Friday 8:00 AM - 6:00 PM ET.
              </p>
              <p>
                <a id="contactCanIGet" name="contactCanIGet"></a>
                Can I get a product sample?
                <br />
                Many of our printed products are available as free samples, so
                you can check a colour or see a typical imprint. Samples are
                only available by calling one of our representatives at
                800.490.6327 Monday - Friday 8:00 AM - 6:00 PM ET. To make sure
                you get the sample you want, please include a specific item
                number if possible.
              </p>
              <p>
                <a id="contactHow" name="contactHow"></a>
                How do I request a catalogue?
                <br /> To receive catalogues with our full line of products for
                your business, simply phone one of our helpful representatives
                at 800.490.6327 Monday - Friday 8:00 AM - 6:00 PM ET.
              </p>
              <p>
                <a id="contactCanI" name="contactCanI"></a>
                Send a comment or suggestion?
                <br />
                Your comments are always welcome. If you have a positive online
                experience, we'd love to hear about it. If you're disappointed
                in us, we truly appreciate suggestions on how to improve your
                experience.
              </p>
              <p>
                <a href="mailto:printing@dfsbusiness.com">E-mail us</a> with
                your feedback. Or mail your comments to:
              </p>
              <p>
                DFS
                <br /> DFS Customer Service
                <br />
                330 Cranston Crescent
                <br />
                Midland, Ontario L4R 4V9
                <br />
              </p>
              <div class="btnRightC">
                <a href="#tableContent">return to top</a>
              </div>
            </div>
            <br />
            <div class="mainHeader">
              <a id="account" name="account"></a>
              <h2>MY ACCOUNT/SIGNING IN</h2>
              <br />
            </div>
            <div class={styles.helpHeader}>
              <p>
                <a id="accountWhat" name="accountWhat"></a>
                What if I forget my password?
                <br />
                If you forget your password, go to the Sign In page and click
                our{" "}
                <a href={`${PREVIEW}/signin.html?vid=${VID}&amp;mt=1`}>
                  forgot your password
                </a>{" "}
                ? link for returning customers. After entering the email address
                associated with your account, you will receive an email with
                instructions on how to reset.
              </p>

              <p>
                <a id="accountWhatAre" name="accountWhatAre"></a>
                What are my payment options?
                <br />
                Payments can be made by Credit Card, payable in Canadian
                dollars. We accept Visa, Mastercard, and American Express. Your
                card is charged when your order ships, usually within 3 to 5
                business days. All online credit card orders are secure.
              </p>

              <div class="btnRightC">
                <a href="#tableContent">return to top</a>
              </div>
            </div>
            <br />
            <div class="mainHeader">
              <a id="orders" name="orders"></a>
              <h2>ORDERS &amp; REORDERS</h2>
              <br />
            </div>
            <div class={styles.helpHeader}>
              <p>
                <a id="ordersHow" name="ordersHow"></a>
                How do I find products for my software?
                <br />
                DFS computer forms and cheques are compatible with 100% of
                today's popular accounting programs. If you can’t find what you
                are looking for, our sales and service representatives are happy
                to assist you - simply call 800.490.6327 Monday – Friday 8:00 AM
                – 6:00 PM ET.
              </p>
              <p>
                <a id="ordersWill" name="ordersWill"></a>
                Will I see my total cost before ordering?
                <br />
                Yes. Your total cost, including tax, will be displayed for your
                review before you submit your order.
                <br />
              </p>
              <p>
                <a id="ordersHowDo" name="ordersHowDo"></a>
                How do I check order status?
                <br /> Sign in to{" "}
                <a
                  href={`${PREVIEW}/myaccount.html?mode=billingaddress&amp;vid=${VID}`}
                >
                  My Account
                </a>{" "}
                and select the "View Orders" link at left. You'll see a
                chronological summary of recent orders, including their current
                shipping status.
              </p>
              <p>
                <a id="ordersCanI" name="ordersCanI"></a>
                Can I change or cancel an order?
                <br />
                Contact a DFS Customer Service Representative at 800.490.6327
                Monday through Friday 8:00 AM to 6:00 PM ET and include your
                order confirmation number. If your order has already been
                processed, we can give you a full credit, replacement or refund
                after you take delivery.
              </p>
              <p>
                <a id="ordersHowDoI" name="ordersHowDoI"></a>
                How do I reorder?
                <br /> Its easy! Sign in to{" "}
                <a
                  href={`${PREVIEW}/myaccount.html?mode=billingaddress&amp;vid=${VID}`}
                >
                  My Account
                </a>{" "}
                and go to the "View Orders" section. You'll see the details of
                past orders, with a "Reorder" button to the right of each
                product. The link will initiate the verification and reorder
                process.
              </p>
              <p>
                <a id="ordersWhat" name="ordersWhat"></a>
                What if I'm not satisfied?
                <br />
                For 90 days from the day we ship your order, you are covered by
                our 100% Quality Guarantee which is applicable to all Printed
                Products. We will replace, refund or credit any of our
                manufactured products found to contain printing errors caused by
                us, or to be defective in workmanship or materials.
              </p>
              <div class="btnRightC">
                <a href="#tableContent">return to top</a>
              </div>
            </div>
            <br />

            <div class="mainHeader">
              <a id="shipping" name="shipping"></a>
              <h2>SHIPPING &amp; HANDLING</h2>
              <br />
            </div>
            <div class={styles.helpHeader}>
              <p>
                <a id="shippingHow" name="shippingHow"></a>
                How is my order shipped?
                <br />
                Items ship free, using standard ground shipping, specialty
                orders excluded. Expedited service may be requested for an
                additional cost.
              </p>
              <p>
                <a id="shippingHowLong" name="shippingHowLong"></a>
                How long will my order take?
                <br />
                Most standard items ship within 3 working days. Orders with new
                logos, custom orders and specialty orders require at least 10
                working days for set-up and production.
              </p>
              <p>
                You will be notified by e-mail when your order ships. Please
                allow time for Standard Ground delivery.
              </p>
              <div class="btnRightC">
                <a href="#tableContent">return to top</a>
              </div>
            </div>
            <br />
            <div class="mainHeader">
              <a id="site" name="site"></a>
              <h2>SITE SECURITY</h2>
              <br />
            </div>
            <div class={styles.helpHeader}>
              <p>
                <a id="siteAre" name="siteAre"></a>
                Are my online cheque orders secure?
                <br />
                Any screen on our Web site that contains your confidential
                information is secure. Some pages on our site are not secure
                because they do not contain any of your confidential
                information. But entry of all personal information and orders on
                this Web site is always done through secure methods. Click our
                Site Security page for details.
              </p>
              <p>
                <a id="siteWhat" name="siteWhat"></a>
                What do you do with my information?
                <br />
                DFS has a long history of recognizing and protecting the privacy
                of consumers, and we're committed to protecting your privacy. To
                learn more, see our{" "}
                <Link to="/why-choose-dfs/dfs-policies/privacy-policy/">
                  Privacy Policy
                </Link>
                .
              </p>

              <div class="btnRightC">
                <a href="#tableContent">return to top</a>
              </div>
            </div>
            <br />

            <div class="mainHeader">
              <a id="pcsHelp" name="pcsHelp"></a>
              <h2>YOUR DFS PROMOTION OR COUPON CODE</h2>
              <br />
            </div>
            <div class={styles.helpHeader}>
              <p>
                <a id="pcsHelpWhat" name="pcsHelpWhat"></a>
                What is your Promotion or Coupon Code?
                <br /> Your Promotion or Coupon Code connects you to possible
                savings on products and services offered by DFS. In order to
                apply your special offer you must enter your Promotion or Coupon
                Code on the Shopping Cart page. Your discounted price or special
                offer will be reflected in the shopping cart.
              </p>
              <p>
                <a id="pcsHelpWhere" name="pcsHelpWhere"></a>
                Where can I find my Promotion or Coupon Code?
                <br /> Your Promotion or Coupon Code is a 5-6 character code
                printed on postcards, emails, and other correspondence.
              </p>
              <p>
                <a id="pcsHelpHow" name="pcsHelpHow"></a>
                How do you qualify?
                <br /> To see if your Promotion or Coupon Code is tied to a
                discount or special offer you must enter it on the DFS site and
                click Go. Each Promotion or Coupon Code is tied to specific
                criteria and may be based on your order history. Once you have
                entered your Promotion or Coupon Code, you will be given
                information and guidelines that state your eligibility.
              </p>
              <p>
                <a id="pcsHelpMultiple" name="pcsHelpMultiple"></a>
                What if I have more than one Promotion or Coupon Code?
                <br /> It's possible you received more than one special offer,
                however, only one Promotion or Coupon Code can be used per
                order.
              </p>
              <div class="btnRightC">
                <a href="#tableContent">return to top</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerServiceTopics;
